import { createApp } from 'vue'
import Toaster from "@meforma/vue-toaster";
import App from './App.vue'
import router from './router'
import store from './store'
import pagination from 'laravel-vue-pagination';
// import AnimatedVue from 'animated-vue'



const app = createApp(App).use(Toaster).use(store).use(router)
app.component('pagination', pagination)
app.mixin({
    computed: {
        url(){
          return this.$store.state.url
        },   
       
        headers(){
          return {
            headers:{
              "Authorization":"Bearer "+this.$store.state.user.access
            }
          }
        }
      },
      methods: {
        datetime(x) {
          if (!x) return "-";
          
          let date = new Date(x);
      
          // Extract day, month, and year
          let day = date.getDate().toString().padStart(2, '0'); // Get day and pad single digits with '0'
          let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, add 1, and pad with '0'
          let year = date.getFullYear(); // Get full year
          
          // Return the formatted date as dd-mm-yyyy
          return `${day}-${month}-${year}`;
      },
      
        money(x) {
            x = parseInt(x).toFixed(0)
            if(!x) return "-";
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          },
      },
  
})
app.mount('#app')
